// Header.js 
import React, { useState, useRef, useEffect } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { Image, Icon, Popup, Button } from "semantic-ui-react";
import { useMouseInside } from "../useMouseInside";
import "./Header.css";
import { signOut as firebaseSignOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { useAuth } from '../../AuthContext';
import { getDatabase, ref, get, set } from 'firebase/database';

const Header = () => {
  const [isExpanded, setIsExpanded] = useState(false);
  const [burgerClicked, setBurgerClicked] = useState(false);
  const [disableHover, setDisableHover] = useState(false);
  const [clickedOpen, setClickedOpen] = useState(false);
  const [userRole, setUserRole] = useState('');
  const [weather, setWeather] = useState({ temp: null, icon: "sun" }); 


  
  const sidebarRef = useRef();
  const burgerRef = useRef();

  const isMouseInsideSidebar = useMouseInside(sidebarRef);
  const isMouseInsideBurger = useMouseInside(burgerRef);

  const navigate = useNavigate();
  const { signOut } = useAuth(); 
 
  useEffect(() => {
    const storedUserRole = localStorage.getItem('userRole'); 
    setUserRole(storedUserRole || ''); 
  }, []);

  useEffect(() => {
    navigator.geolocation.getCurrentPosition((position) => {
      fetchWeather(position.coords.latitude, position.coords.longitude);
    });
  }, []);
  

  useEffect(() => {
    if (burgerClicked || (isMouseInsideBurger && isMouseInsideSidebar && !disableHover)) {
      setIsExpanded(true);
    } else if (!burgerClicked) {
      setIsExpanded(isMouseInsideBurger && !disableHover);
    }
  }, [isMouseInsideSidebar, isMouseInsideBurger, burgerClicked, disableHover]);

  const toggleSidebar = () => {
    setBurgerClicked(!burgerClicked);
    if (burgerClicked) {
      setDisableHover(true);
    }
    setIsExpanded(!burgerClicked);
    setClickedOpen(!burgerClicked);
  };

   
   const handleSignOut = async () => {
    try {
      await endSession();
      await firebaseSignOut(auth);
      signOut();
      console.log("Signed out successfully");
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const fetchWeather = async (lat, lon) => {
    const apiKey = "12576dfc25ef6b3c44fff72b83919795";
    const url = `https://api.openweathermap.org/data/2.5/weather?lat=${lat}&lon=${lon}&appid=${apiKey}&units=imperial`;

    try {
      const response = await fetch(url);
      const data = await response.json();
      const isDay = new Date().getHours() >= 6 && new Date().getHours() <= 18;
      setWeather({
        temp: Math.round(data.main.temp),
        icon: isDay ? "sun outline" : "moon outline", 
      });
    } catch (error) {
      console.error("Failed to fetch weather data:", error);
    }
  };

  const endSession = async () => {
    const sessionId = localStorage.getItem('currentSessionId');
    const db = getDatabase();
    const sessionsRef = ref(db, 'sessions');

    
    const sessionsSnapshot = await get(sessionsRef);
    if (sessionsSnapshot.exists()) {
        const sessions = sessionsSnapshot.val();
        
        for (const [key, session] of Object.entries(sessions)) {
            if (!session.endTime && session.deviceIdentifier === getDeviceIdentifier()) { 
                const sessionToUpdateRef = ref(db, `sessions/${key}`);
                await set(sessionToUpdateRef, { ...session, endTime: new Date().toISOString() });
                console.log(`Ended previous active session: ${key}`);
            }
        }
    }

    if (!sessionId) {
        console.error("No session ID found for ending session.");
        return;
    }

    function getDeviceIdentifier() {
      let deviceIdentifier = localStorage.getItem('deviceIdentifier');
      if (!deviceIdentifier) {
          deviceIdentifier = `${navigator.userAgent}-${Math.random().toString(36).substring(2, 15)}`;
          localStorage.setItem('deviceIdentifier', deviceIdentifier);
      }
      return deviceIdentifier;
  }
}

  const enableHover = () => {
    if (disableHover) {
      setDisableHover(false);
    }
  };

  const handleLinkClick = () => {
    if (clickedOpen) {
      toggleSidebar();
    }
  };

  return (
    <div>
      <div>
        <div className={`header ${isExpanded ? "expanded" : ""}`}>
          <div
            className="burger-button"
            onClick={toggleSidebar}
            ref={burgerRef}
            onMouseEnter={() => !disableHover && setIsExpanded(true)}
            onMouseLeave={() => {
              enableHover();
              !burgerClicked && setIsExpanded(false);
            }}
          >
            <Icon name={burgerClicked ? "x" : "bars"} />
          </div>
          <Link to="/inventory" className="header-logo">
            <Image src="\CFBlogo.png" alt="logo" className="custom-logo" />
          </Link>
          <div className="header-text">Cashmere Food Bank</div>
          <div className={`mini-text ${isExpanded ? "expanded-text" : ""}`}>
            Dashboard
          </div>
        </div>

        <div className={`sidebar ${isExpanded ? "expanded" : ""}`} ref={sidebarRef}>
          <Link to="/inventory" className="custom-button" data-desc="View and Search inventory items" onClick={handleLinkClick}>
            <Icon name="warehouse" />
            <span className={`sidebar-text ${isExpanded ? "expanded" : ""}`}>
              Inventory
            </span>
          </Link>

          <Link to="/add" className="custom-button" data-desc="Add New inventory items" onClick={handleLinkClick}>
            <Icon name="plus square outline" />
            <span className={`sidebar-text ${isExpanded ? "expanded" : ""}`}>
              Add/New
            </span>
          </Link>
          <Link to="/remove" className="custom-button" data-desc="Remove TEFAP inventory items" onClick={handleLinkClick}>
            <Icon name="minus square outline" />
            <span className={`sidebar-text ${isExpanded ? "expanded" : ""}`}>
              Remove
            </span>
          </Link>
          <Link to="/stats" className="custom-button" data-desc="Review Inventory Statistics " onClick={handleLinkClick}>
            <Icon name="chart bar" />
            <span className={`sidebar-text ${isExpanded ? "expanded" : ""}`}>
              Statistics
            </span>
          </Link>
          <Link to="/settings" className="custom-button" data-desc="Modify Settings" onClick={handleLinkClick}>
            <Icon name="cog" />
            <span className={`sidebar-text ${isExpanded ? "expanded" : ""}`}>
              Settings
            </span>
          </Link>
        </div>
      </div>
      
      <div className="user-profile">
        <div className="weather-info" style={{ marginBottom: '30px' }}>
          <Icon name={weather.icon} size="large" />
          {weather.temp !== null ? <span>{weather.temp}°F</span> : <span>Loading...</span>}
        </div>

        <Popup
          trigger={<Icon name="user circle" className="user-profile-pic" style={{ cursor: 'pointer' }} />}
          content={<Button color="red" onClick={handleSignOut}>Sign Out</Button>}
          on="click"
          position="top center"
          className="user-profile-popup"
        />
        
        <div className="user-info">
          <span className="user-role">{userRole}</span>
          
        </div>
      </div>
    </div>
      
  );
};

export default Header;
