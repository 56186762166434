// DynamicSettings.js 
import React from 'react';
import Settings from './Settings';
import VolSettings from '../VolComponents/VolSettings';

const DynamicSettings = () => {
  const userRole = localStorage.getItem('userRole');
  console.log(userRole);

  if (userRole === 'Admin' || userRole === 'Top Admin') {
    return <Settings />;
  } else if (userRole === 'Volunteer') {
    return <VolSettings />;
  } else {
    return <div>No valid role found</div>; 
  }
};

export default DynamicSettings;

