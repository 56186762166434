import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { AuthProvider } from '../../AuthContext'; // Adjust the import path if necessary
import Inventory from './Inventory';
import Login from '../AuthComponents/Login';
import Add from './Add';
import Remove from './Remove';
import Stats from './Stats';
import ProtectedRoute from '../AuthComponents/ProtectedRoute';
import AdminLogin from '../AuthComponents/AdminLogin';
import VolunteerLogin from '../VolComponents/VolunteerLogin';


import DynamicSettings from './DynamicSettings';

const AppContent = () => {

  return (
    <BrowserRouter>
      <Routes>
        {/* Public routes */}
        <Route path="/login" element={<Login />} />
        <Route path="/admin-login" element={<AdminLogin />} />
        <Route path="/volunteer-login" element={<VolunteerLogin />} />

        {/* Protected routes, accessible based on user role */}
        <Route path="/inventory" element={<ProtectedRoute><Inventory /></ProtectedRoute>} />
        <Route path="/stats" element={<ProtectedRoute><Stats /></ProtectedRoute>} />
        <Route path="/add" element={<ProtectedRoute><Add /></ProtectedRoute>} />
        <Route path="/remove" element={<ProtectedRoute><Remove /></ProtectedRoute>} />
        <Route path="/settings" element={<ProtectedRoute><DynamicSettings /></ProtectedRoute>} />

        {/* Redirect un-authenticated or unauthorized users */}
        <Route path="*" element={<Navigate to="/login" replace />} />
      </Routes>
    </BrowserRouter>
  );
};

// Wrap your app content with AuthProvider to provide authentication context
const App = () => {
  return (
    <AuthProvider>
      <AppContent />
    </AuthProvider>
  );
};

export default App;
