// Admin.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { signInWithEmailAndPassword, sendPasswordResetEmail } from "firebase/auth";
import { getDatabase, ref, set, get } from "firebase/database";
import { auth } from "../../firebaseConfig";
import { Button, Form, Message, Icon, Modal, Input } from "semantic-ui-react";

import './AdminLogin.css';

const AdminLogin = () => {
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');
    const [loginError, setLoginError] = useState('');
    const [openForgotPasswordModal, setOpenForgotPasswordModal] = useState(false);
    const [resetEmail, setResetEmail] = useState('');
    const [showPassword, setShowPassword] = useState(false);
    const [isEmailValid, setIsEmailValid] = useState(true); 
    const [openConfirmationModal, setOpenConfirmationModal] = useState(false); 
    const navigate = useNavigate();

    
    const handleLogin = async (e) => {
        e.preventDefault();
        try {
            const userCredential = await signInWithEmailAndPassword(auth, email, password);
            const user = userCredential.user;
    
            const db = getDatabase();
            const roleRef = ref(db, `users/${user.uid}/role`);
            const roleSnapshot = await get(roleRef);
    
            if (roleSnapshot.exists() && (roleSnapshot.val() === 'Admin' || roleSnapshot.val() === 'Top Admin')) {
                console.log(roleSnapshot.val() + " Signed-in successfully");
                localStorage.setItem('userRole', roleSnapshot.val()); 
                startSession(user.uid, roleSnapshot.val(), user.email);
                navigate('/inventory', { state: { userType: roleSnapshot.val() } });
            } else {
                throw new Error('Not authorized as admin or Top admin');
            }
        } catch (error) {
            if (error.code === 'auth/wrong-password') {
                setLoginError("The password is incorrect. Please try again.");
            } else {
                setLoginError("Login failed. Please try again.");
            }
            console.error("Error during login:", error);
        }
    };
    

    function getDeviceIdentifier() {
        let deviceIdentifier = localStorage.getItem('deviceIdentifier');
        if (!deviceIdentifier) {
            deviceIdentifier = `${navigator.userAgent}-${Math.random().toString(36).substring(2, 15)}`;
            localStorage.setItem('deviceIdentifier', deviceIdentifier);
        }
        return deviceIdentifier;
    }
    
    const validateEmail = (email) => {
        const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    };
    
    
    const handleForgotPassword = async () => {
        if (resetEmail && validateEmail(resetEmail)) {
            try {
                await sendPasswordResetEmail(auth, resetEmail);
               
                setOpenConfirmationModal(true);
            } catch (error) {
                console.error("Error sending password reset email:", error);
              
            }
        } else {
            setIsEmailValid(false);
        }
    };

    const handleCloseModals = () => {
        setOpenConfirmationModal(false);
        setOpenForgotPasswordModal(false); 
        setResetEmail('');
        setIsEmailValid(true);
    };
    
    

    const startSession = async (userId, userType, userEmail) => {
        const db = getDatabase();
        const sessionsRef = ref(db, 'sessions');
        const deviceIdentifier = getDeviceIdentifier();
        const sessionsSnapshot = await get(sessionsRef);
        if (sessionsSnapshot.exists()) {
            const sessions = sessionsSnapshot.val();
            for (const [key, session] of Object.entries(sessions)) {
                if (session.userId === userId && !session.endTime) {
                    const sessionToUpdateRef = ref(db, `sessions/${key}`);
                    await set(sessionToUpdateRef, { ...session, endTime: new Date().toISOString() });
                    console.log(`Ended previous active session for user: ${userId}`);
                }
            }
        }
    
        const sessionId = `${userId}-${Date.now()}`;
        localStorage.setItem('currentSessionId', sessionId);
        const newSessionRef = ref(db, `sessions/${sessionId}`);
        const newSession = {
            startTime: new Date().toISOString(),
            endTime: null,
            userType,
            userId,
            userEmail,
            deviceIdentifier,
        };
        await set(newSessionRef, newSession);
        console.log("New session started:", sessionId);
    };
    


    return (
        <div className="admin-login-container">
            <h1>Admin Login</h1>
            <Form onSubmit={handleLogin}>
                <Form.Input
                    type="email"
                    placeholder="Email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    required
                />
                <div className="password-input-wrapper">
                    <Form.Input
                        type={showPassword ? "text" : "password"} 
                        placeholder="Password"
                        value={password}
                        onChange={(e) => setPassword(e.target.value)}
                        required
                        
                        icon={<Icon name={showPassword ? 'eye slash' : 'eye'} link onClick={() => setShowPassword(!showPassword)} />} 
                    />
                </div>
                <Button type="submit">Login</Button>
                {loginError && <Message negative>{loginError}</Message>}
                <div className="forgot-password-text" onClick={() => setOpenForgotPasswordModal(true)} style={{ cursor: 'pointer', marginTop: '10px' }}>
                     Forgot Password?
                </div>

                <Modal
                    open={openForgotPasswordModal}
                    onClose={() => setOpenForgotPasswordModal(false)}
                    size="small"
                >
                    <Modal.Header>Reset Your Password</Modal.Header>
                    <Modal.Content style={{ marginTop: '20px' }}>
                        <p>Please enter your email address to receive a password reset link.</p>
                        <Input
                            type="email"
                            placeholder="Email"
                            value={resetEmail}
                            onChange={(e) => {
                                setResetEmail(e.target.value);
                                setIsEmailValid(true); 
                            }}
                            fluid
                            error={!isEmailValid} 
                        />
                        {!isEmailValid && <Message negative>Email is invalid.</Message>}
                    </Modal.Content>
                    <Modal.Actions>
                        <Button onClick={() => setOpenForgotPasswordModal(false)} floated='left'>Cancel</Button>
                        <Button positive onClick={handleForgotPassword} style={{ backgroundColor: 'rgb(143, 185, 143)', color: 'white' }}>Send Reset Email</Button>
                            <Modal
                                open={openConfirmationModal}
                                onClose= {handleCloseModals}
                                size="small"
                            >
                                <Modal.Header>Password Reset Email Sent</Modal.Header>
                                <Modal.Content style={{ marginTop: '20px' }}>
                                    <p>Password reset email sent successfully. Please check your inbox.</p>
                                </Modal.Content>
                                <Modal.Actions>
                                    <Button onClick={handleCloseModals}>OK</Button>
                                </Modal.Actions>
                            </Modal>
                        </Modal.Actions>

                </Modal>

            </Form>
            
        </div>
    );
};

export default AdminLogin;
