// Inventory.js

import React, { useState, useEffect } from "react";
import { Table, Grid, Segment } from 'semantic-ui-react';
import { getDatabase, ref, onValue} from "firebase/database";
import "./Inventory.css";

const usePersistentState = (key, defaultValue) => {
  const [state, setState] = useState(() => {
    const storedValue = localStorage.getItem(key);
    return storedValue !== null ? JSON.parse(storedValue) : defaultValue;
  });

  useEffect(() => {
    localStorage.setItem(key, JSON.stringify(state));
  }, [key, state]);

  return [state, setState];
};


const Inventory = () => {
  const [inventory, setInventory] = useState([]);
  const [inWeight, setInWeight] = useState(0); 
  const [outWeight, setOutWeight] = useState(0);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [totalItemsCount, setTotalItemsCount] = useState(0);
  const [prevTotalItemsCount, setPrevTotalItemsCount] = useState(0);
  const [donorColors, setDonorColors] = usePersistentState('donorColors', {});
  const db = getDatabase();
  const inventoryRef = ref(db, 'inventory');
  const inWeightRef = ref(db, 'info/inWeight');
  const outWeightRef = ref(db, 'info/outWeight');

  useEffect(() => {
    
    const unsubscribeInventory = onValue(inventoryRef, (snapshot) => {
      let allItems = [];
      snapshot.forEach((snap) => {
        const item = snap.val();
        allItems.push({ id: snap.key, item });
      });
      setPrevTotalItemsCount(totalItemsCount); 
      setInventory(allItems);
      setTotalItemsCount(allItems.length); 
    });
  
    
    return () => unsubscribeInventory();
  }, [db, totalItemsCount]);

  
  useEffect(() => {
    
    const unsubscribeInventory = onValue(inventoryRef, (snapshot) => {
      let allItems = [];
      snapshot.forEach((snap) => {
        const item = snap.val();
        allItems.push({ id: snap.key, item });
      });
      setInventory(allItems);
    });

    const unsubscribeInWeight = onValue(inWeightRef, (snapshot) => {
      const newInWeight = snapshot.val() || 0;
      setInWeight(newInWeight);
    });

    const unsubscribeOutWeight = onValue(outWeightRef, (snapshot) => {
      const newOutWeight = snapshot.val() || 0;
      setOutWeight(newOutWeight);
    });

   
    return () => {
      unsubscribeInventory();
      unsubscribeInWeight();
      unsubscribeOutWeight();
    };
  }, [db]); 

  useEffect(() => {
    
  
   
  }, [db]);

  useEffect(() => {
    const colorsRef = ref(db, 'donors');
    onValue(colorsRef, (snapshot) => {
        const fetchedColors = {};
        snapshot.forEach(snap => {
            fetchedColors[snap.key] = snap.val().color;
        });
        setDonorColors(fetchedColors);
    });
}, [db]);

  

  const getDonorColor = (donor) => {
    const predefinedColors = {
        'USDA - TEFAP': '#8FBC8F',
        'Chelan-Douglas CAC': '#F08080',
        'Northwest Harvest': 'BurlyWood',
        'Second Harvest': '#20B2AA',
        'Other': 'gray'  
    };

    if (predefinedColors[donor]) {
        return predefinedColors[donor];
    }

    if (donorColors[donor]) {
        return donorColors[donor];
    }

    return '#cccccc';  
};

  
  

  const filteredInventory = inventory.filter((item) => {
    const itemName = item.item.itemName ? item.item.itemName.toLowerCase() : "";
    const category = item.item.category ? item.item.category.toLowerCase() : "";
    const subCategory = item.item.subCategory ? item.item.subCategory.toLowerCase() : "";
    const date = item.item.dateAdded ? item.item.dateAdded() : "";
    const time = item.item.timeAdded ? item.item.timeAdded() : "";
    return itemName.includes(searchQuery.toLowerCase()) ||
           category.includes(searchQuery.toLowerCase()) ||
           subCategory.includes(searchQuery.toLowerCase()) ||
           date.includes(searchQuery.toLowerCase()) ||
           time.includes(searchQuery.toLowerCase());
  });

  const sortedInventory = filteredInventory.sort((a, b) => {
    switch (sortBy) {
      case "category":
        return a.item.category.localeCompare(b.item.category);
      case "subCategory":
        return a.item.subCategory.localeCompare(b.item.subCategory);
      case "donor":
        return a.item.donor.localeCompare(b.item.donor);
      case "expirationDate":
        return new Date(b.item.expirationDate) - new Date(a.item.expirationDate);
      case "dateAdded":
        return new Date(b.item.date) - new Date(a.item.date);
      case "timeAdded":
        return new Date(b.item.time) - new Date(a.item.time);
      default:
        return 0;
    }
  });

  

  return (
    <div className="Inventory">
      <div className="inventory-section-header">
        <h3>Inventory </h3>
      </div>
      <Grid columns={4} divided>
        <Grid.Row>
          <Grid.Column>
            <Segment>
              <h3>Total In Weight:</h3>
              <p>{(+inWeight.toFixed(2))} lbs.</p>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <h3>Total Out Weight:</h3>
              <p>{(+outWeight.toFixed(2))} lbs.</p>
            </Segment>
          </Grid.Column>
          <Grid.Column>
            <Segment>
              <h3>Difference:</h3>
              <p>{Math.abs((inWeight - outWeight).toFixed(2))} lbs.</p>
            </Segment>
          </Grid.Column>
          <Grid.Column> {/* New column for total items count */}
            <Segment>
              <h3>Total Item Count:</h3>
              <p>
                {totalItemsCount}
                
              </p>
            </Segment>
          </Grid.Column>
        </Grid.Row>
      </Grid>

      <div className="search-bar">
        <input
          type="text"
          placeholder="Search by Name or Category..."
          value={searchQuery}
          onChange={e => setSearchQuery(e.target.value)}
        />
        <select value={sortBy} onChange={e => setSortBy(e.target.value)}>
          <option value="">Sort By</option>
          <option value="category">Category</option>
          <option value="subCategory">Subcategory</option>
          <option value="donor">Donor</option>
          <option value="expirationDate">Expiration Date</option>
          <option value="dateAdded">Date Added</option>
          <option value="timeAdded">Time Added</option> {/* Corrected option value */}
        </select>
      </div>
      <Table celled>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Item Name</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>Weight</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Subcategory</Table.HeaderCell>
            <Table.HeaderCell>Donor</Table.HeaderCell>
            <Table.HeaderCell>Expiration Date</Table.HeaderCell>
            <Table.HeaderCell>Date Added</Table.HeaderCell>
            <Table.HeaderCell>Time Added</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedInventory.map(({ id, item }) => (
            <Table.Row key={id}>
            <Table.Cell>{item.itemName}</Table.Cell>
            <Table.Cell>{item.quantity}</Table.Cell>
            <Table.Cell>{item.weight}</Table.Cell>
            <Table.Cell>{item.category}</Table.Cell>
            <Table.Cell>{item.subCategory}</Table.Cell>
            <Table.Cell>
              <span
                style={{
                  height: '8px',
                  width: '8px',
                  backgroundColor: getDonorColor(item.donor),
                  borderRadius: '50%',
                  display: 'inline-block',
                  marginRight: '5px', 
                }}
              ></span>
              {item.donor}
            </Table.Cell>
          <Table.Cell>{item.expirationDate}</Table.Cell>
          <Table.Cell>{item.date}</Table.Cell>
          <Table.Cell>{item.time}</Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>

      </Table>
    </div>
  );
};

export default Inventory;
