import React, { useState, useEffect } from 'react';
import { getDatabase, ref, onValue, remove, set, get, increment, update, push } from 'firebase/database';
import { Button, Input, Table, Modal } from 'semantic-ui-react';
import './Remove.css';

const RemovedItems = () => {
  const [removedItems, setRemovedItems] = useState([]);
  const [weightToRemove, setWeightToRemove] = useState("");
  const [emptyBoxWeight, setEmptyBoxWeight] = useState("1.4");
  const [showTEFAP, setShowTEFAP] = useState(true);
  const [searchQuery, setSearchQuery] = useState("");
  const [sortBy, setSortBy] = useState("");
  const [showPopup, setShowPopup] = useState(false);
  const [outWeight, setOutWeight] = useState(0);
  const [successMessage, setSuccessMessage] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [includeBoxWeight, setIncludeBoxWeight] = useState(false);

  const [donorColors, setDonorColors] = useState({});
  const db = getDatabase();

  useEffect(() => {
    let isMounted = true;
    const inventoryRef = ref(db, 'inventory');
    const outWeightRef = ref(db, 'info/outWeight');

    const unsubscribeInventory = onValue(inventoryRef, (snapshot) => {
      if (snapshot.exists() && isMounted) {
        let tefapItems = [];
        let nonTefapItems = [];
        snapshot.forEach(snap => {
          const item = snap.val();
          if (item.donor === 'USDA - TEFAP') {
            tefapItems.push({ id: snap.key, ...item });
          } else {
            nonTefapItems.push({ id: snap.key, ...item });
          }
        });

        setRemovedItems(showTEFAP ? tefapItems : nonTefapItems);
      } else if (isMounted) {
        setRemovedItems([]);
      }
    });

    const unsubscribeOutWeight = onValue(outWeightRef, (snapshot) => {
      if (isMounted) {
        const newOutWeight = snapshot.val() || 0;
        setOutWeight(newOutWeight);
      }
    });

    return () => {
      isMounted = false;
      unsubscribeInventory();
      unsubscribeOutWeight();
    };
  }, [db, showTEFAP]);




  useEffect(() => {
    const donorsRef = ref(db, 'donors');
    onValue(donorsRef, (snapshot) => {
      if (snapshot.exists()) {
        let fetchedColors = {};
        snapshot.forEach(snap => {
          fetchedColors[snap.key] = snap.val().color; 
        });
        setDonorColors(fetchedColors);
        console.log("Updated donor colors:", fetchedColors); 
      }
    });
  }, [db]);


  const getDonorColor = (donor) => {
    const color = donorColors[donor];
    return color ? (color.startsWith('#') ? color : `#${color}`) : 'black';
  };

  const renderDetails = (newAction) => {
    return (
      <div>
        <p><strong>Type:</strong> {newAction.type}</p>
        <p><strong>Item:</strong> {newAction.details.itemName}</p>
        <p><strong>Quantity:</strong> {newAction.details.quantity}</p>
        <p><strong>Weight:</strong> {newAction.details.weight} lbs</p>
        <p><strong>Category:</strong> {newAction.details.category}</p>
        <p><strong>Subcategory:</strong> {newAction.details.subCategory}</p>
        <p><strong>Donor:</strong> {newAction.details.donor}</p>
        <p><strong>Expiration Date:</strong> {newAction.details.expirationDate}</p>
        <p><strong>Date Added:</strong> {newAction.details.date}</p>
        <p><strong>Time Added:</strong> {newAction.details.time}</p>
        <p><strong>Removal Date:</strong> {newAction.details.removalDate}</p>

      </div>
    );
  };

  const handleRemoveItem = async (id) => {
    const db = getDatabase();
    const itemRef = ref(db, `inventory/${id}`);
    try {
      const snapshot = await get(itemRef);
      if (!snapshot.exists()) {
        console.error("Item does not exist:", id);
        return;
      }
      const itemData = snapshot.val(); 
      const currentDate = new Date().toISOString(); 

      const container = itemData.tefap ? 'removed-tefap' : 'removed';
      const removedRef = ref(db, `${container}/${id}`);
      setShowPopup(true);
      setSuccessMessage("Item successfully removed!");
      setTimeout(() => setShowPopup(false), 2000);
      console.log("OutWeight updated successfully.");

      const sessionId = localStorage.getItem('currentSessionId');
      if (sessionId) {
        const sessionActionsRef = ref(db, `sessions/${sessionId}/actions`);
        const newAction = {
          type: 'remove',
          details: {
            ...itemData,
            removalDate: new Date().toISOString(),
          },
          timestamp: currentDate,
        };

        console.log("Rendering details for action:", newAction);
        const detailsString = renderDetails(newAction);
        console.log(detailsString);
        await push(sessionActionsRef, newAction);
        console.log(`Removal of item logged in session:`, sessionId, newAction);
        const totalWeightToRemove = parseFloat(itemData.weight) + (includeBoxWeight ? parseFloat(emptyBoxWeight) : 0);
        const outWeightUpdate = {};
        outWeightUpdate['/info/outWeight'] = increment(totalWeightToRemove);
        await update(ref(db), outWeightUpdate);
        await set(removedRef, { ...itemData, removalDate: currentDate });
        await remove(itemRef);
        console.log("Item successfully removed and logged:", id);
        setOutWeight((prevOutWeight) => prevOutWeight.toFixed(2) + totalWeightToRemove.toFixed(2));
  
      } else {
        console.error("Session ID not found for logging removal.");
      }
    } catch (error) {
      console.error("Error during item removal and logging:", error);
    }
  };

  const handleRemoveWeight = async () => {
    
    if (weightToRemove.trim() === "" || emptyBoxWeight.trim() === "") {
      setErrorMessage("Both fields must be filled out.");
      setShowErrorModal(true); 

      return;
    }

    const weightToRemoveNum = parseFloat(weightToRemove);
    const boxWeight = parseFloat(emptyBoxWeight);

    if (isNaN(weightToRemoveNum) || isNaN(boxWeight)) {
      setErrorMessage("Both fields must contain valid numbers.");
      setShowErrorModal(true);
      return;
    }

    if (weightToRemoveNum <= 0) {
      setErrorMessage("Weight to Remove must be greater than zero.");
      setShowErrorModal(true);
      return;
    }

    if (boxWeight <= 0) {
      setErrorMessage("Weight of Empty Box must be greater than zero.");
      setShowErrorModal(true);
      return;
    }

    const netWeightToRemove = weightToRemoveNum.toFixed(2) - boxWeight.toFixed(2);
    
    if (netWeightToRemove <= 0) {
      setErrorMessage("Net weight to remove must be greater than zero. Ensure the Weight to Remove is greater than the Weight of Empty Box.");
      setShowErrorModal(true);
      return;
    }



    const sessionId = localStorage.getItem('currentSessionId');
    if (sessionId) {
      const sessionActionsRef = ref(db, `sessions/${sessionId}/actions`);
      const newAction = {
        type: 'removeWeight',
        details: {
          removedWeight: netWeightToRemove
        },
        timestamp: new Date().toISOString(),
      };
      push(sessionActionsRef, newAction);

      console.log(`${netWeightToRemove} lbs of weight removed added to session log at`, new Date().toLocaleString());
    }


    const outWeightUpdate = {};
    outWeightUpdate['/info/outWeight'] = increment(netWeightToRemove);
    update(ref(db), outWeightUpdate)
      .then(() => {
        setShowPopup(true);
        setSuccessMessage("Weight successfully removed!");
        setTimeout(() => setShowPopup(false), 2000);
        console.log("OutWeight updated successfully.");
        setWeightToRemove('');

      })
      .catch(error => {
        console.error("Error updating outWeight:", error);
      });
  };



  const filteredRemovedItems = removedItems.filter((item) => {
    const itemName = item.itemName ? item.itemName.toLowerCase() : "";
    const category = item.category ? item.category.toLowerCase() : "";
    const subCategory = item.subCategory ? item.subCategory.toLowerCase() : "";
    const expirationDate = item.expirationDate ? item.expirationDate.toLowerCase() : "";
    const date = item.date ? item.date.toLowerCase() : "";
    return itemName.includes(searchQuery.toLowerCase()) ||
      category.includes(searchQuery.toLowerCase()) ||
      subCategory.includes(searchQuery.toLowerCase()) ||
      expirationDate.includes(searchQuery.toLowerCase()) ||
      date.includes(searchQuery.toLowerCase());
  });

  const sortedRemovedItems = filteredRemovedItems.sort((a, b) => {
    switch (sortBy) {
      case "category":
        return a.category.localeCompare(b.category);
      case "subCategory":
        return a.subCategory.localeCompare(b.subCategory);
      case "expirationDate":
        return new Date(b.expirationDate) - new Date(a.expirationDate);
      case "dateAdded":
        return new Date(b.date) - new Date(a.date);
      default:
        return 0;
    }
  });

  return (
    <div className="RemovedItems">
      <div className="remove-section-header">
        <h3>Remove Items </h3>
      </div>
      <div className="remove-inputs">
        <div className="input-container">
          <div className="input-field">
            <label>Weight to Remove (lbs):</label>
            <Input
              type="number"
              value={weightToRemove}
              onChange={(e) => setWeightToRemove(e.target.value)}
              min="0"
              iconPosition="left"
            />
          </div>
          <div className="input-field">
            <label>Weight of Empty Box (lbs):</label>
            <Input
              type="number"
              value={emptyBoxWeight}
              onChange={(e) => setEmptyBoxWeight(e.target.value)}
              min="0"
              iconPosition="left"
            />
          </div>
          <Modal open={showErrorModal} onClose={() => setShowErrorModal(false)} size="mini">
            <Modal.Content>
              <p>{errorMessage}</p>
            </Modal.Content>
            <Modal.Actions>
              <Button onClick={() => setShowErrorModal(false)}>Close</Button>
            </Modal.Actions>
          </Modal>
          <Button onClick={handleRemoveWeight} color="red" className='remove-weight-button'>Remove Weight</Button>
          {showPopup && (
            <div className="success-popup">
              {successMessage}
            </div>
          )}
        </div>
      </div>
      <div className="search-and-sort">
        <Input
          type="text"
          placeholder="Search by Name, Category, Subcategory..."
          value={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        <select value={sortBy} onChange={(e) => setSortBy(e.target.value)}>
          <option value="">Sort By</option>
          <option value="category">Category</option>
          <option value="subCategory">Subcategory</option>
          <option value="expirationDate">Expiration Date</option>
          <option value="dateAdded">Date Added</option>
        </select>
        <Button onClick={() => setShowTEFAP(!showTEFAP)} className="show-button">
          {showTEFAP ? "Show All Items" : "Show TEFAP Items"}
        </Button>
      </div>
      <Table>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Remove</Table.HeaderCell>
            <Table.HeaderCell>Item Name</Table.HeaderCell>
            <Table.HeaderCell>Quantity</Table.HeaderCell>
            <Table.HeaderCell>Weight</Table.HeaderCell>
            <Table.HeaderCell>Category</Table.HeaderCell>
            <Table.HeaderCell>Subcategory</Table.HeaderCell>
            <Table.HeaderCell>Donor</Table.HeaderCell>
            <Table.HeaderCell>Expiration Date</Table.HeaderCell>
            <Table.HeaderCell>Date Added</Table.HeaderCell>
            <Table.HeaderCell>Time Added</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sortedRemovedItems.map(item => (
            <Table.Row key={item.id}>
              <Table.Cell>
                <Button onClick={() => handleRemoveItem(item.id, item.weight)} color="red" className="custom-remove-button">Remove</Button>
              </Table.Cell>
              <Table.Cell>{item.itemName}</Table.Cell>
              <Table.Cell>{item.quantity}</Table.Cell>
              <Table.Cell>{item.weight}</Table.Cell>
              <Table.Cell>{item.category}</Table.Cell>
              <Table.Cell>{item.subCategory}</Table.Cell>
              <Table.Cell>
                <span
                  style={{
                    height: '8px',
                    width: '8px',
                    backgroundColor: getDonorColor(item.donor),
                    borderRadius: '50%',
                    display: 'inline-block',
                    marginRight: '5px',
                  }}
                ></span>
                {item.donor}
              </Table.Cell>
              <Table.Cell>{item.expirationDate}</Table.Cell>
              <Table.Cell>{item.date}</Table.Cell>
              <Table.Cell>{item.time}</Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>
    </div>
  );
};

export default RemovedItems;