// Stats.js

import React, { useEffect, useState } from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { getDatabase, ref, onValue } from "firebase/database";
import { Button, Form, Segment } from 'semantic-ui-react';
import './Stats.css';

const Stats = () => {
  const [rawData, setRawData] = useState([]);
  const [data, setData] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState('');
  const [startDate, setStartDate] = useState(undefined);
  const [endDate, setEndDate] = useState(undefined);
  const [categories, setCategories] = useState([]);
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);
  const [pieChartData, setPieChartData] = useState({ count: [], weight: [] });
  const [tefapData, setTefapData] = useState({ count: [], weight: [] });

  const [tefapViewMode, setTefapViewMode] = useState('weight');
  const [donorViewMode, setDonorViewMode] = useState('weight');

  const [donorColors, setDonorColors] = useState({});
  const COLORS = ['#8FBC8F', '#A9A9A9'];

  useEffect(() => {
    const handleResize = () => {
      setScreenWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const dataRef = ref(db, 'inventory');
    onValue(dataRef, (snapshot) => {
      const inventoryData = [];
      const tempCategories = new Set();

      snapshot.forEach(childSnapshot => {
        const item = childSnapshot.val();
        tempCategories.add(item.category);
        inventoryData.push(item);
      });

      setCategories([...tempCategories]);
      setRawData(inventoryData);
      calcValues(inventoryData); 
    });
  }, []);


  useEffect(() => {
    const filteredData = rawData.filter(item => {
      const itemDate = new Date(item.date);
      return (!startDate || itemDate >= new Date(startDate)) &&
        (!endDate || itemDate <= new Date(endDate));
    });

    calcValues(filteredData);
  }, [rawData, startDate, endDate]); 

  useEffect(() => {
    const db = getDatabase();
    const colorsRef = ref(db, 'donors');
    onValue(colorsRef, (snapshot) => {
      const fetchedColors = {};
      snapshot.forEach(snap => {
        fetchedColors[snap.key] = snap.val().color; 
      });
      setDonorColors(fetchedColors);
    });
  }, []);
 

  const getDonorColor = (donor) => {
    const color = donorColors[donor];
    return color ? (color.startsWith('#') ? color : `#${color}`) : 'black';
  };
  

  const filteredData = selectedCategory ? data.filter((item) => item.category === selectedCategory) : data;

  const renderCustomLabel = (props) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload } = props;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30; 
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const labelType = donorViewMode === 'weight' ? 'lbs' : 'items';
  
    return (
      <text x={x} y={y} 
      fill={getDonorColor(payload.name)} 
      textAnchor={x > cx ? 'start' : 'end'} 
      dominantBaseline="central" 
      style={{ fontSize: '15px' }}>
        {`${payload.name}: ${payload.value} ${labelType}`}
      </text>
    );
  };
  
  const renderCustomLabelTefap = (props, viewMode) => {
    const { cx, cy, midAngle, innerRadius, outerRadius, percent, index, payload } = props;
    const RADIAN = Math.PI / 180;
    const radius = outerRadius + 30; // Extend label distance
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);
    const labelType = viewMode === 'weight' ? 'lbs' : 'items';
  
    return (
      <text x={x} y={y} fill={COLORS[index % COLORS.length]} textAnchor={x > cx ? 'start' : 'end'} dominantBaseline="central" style={{ fontSize: '15px' }}>
        {`${payload.name}: ${payload.value} ${labelType}`}
      </text>
    );
  };
  
  const calcValues = (items) => {
    console.log("Received items for processing:", items.length);

    const tempData = {};
    let tefapCount = 0, nonTefapCount = 0, tefapWeight = 0, nonTefapWeight = 0;
    const donorCountData = {}, donorWeightData = {};

    items.forEach((item) => {
      const { category, subCategory, weight, quantity, tefap, donor } = item;
      const key = `${category}-${subCategory}`;
      if (!tempData[key]) tempData[key] = { category, subCategory, weight: 0, quantity: 0 };
      tempData[key].weight += parseFloat(weight) || 0;
      tempData[key].quantity += parseInt(quantity, 10) || 0;

      if (tefap) {
        tefapCount += 1;
        tefapWeight += parseFloat(weight) || 0;
      } else {
        nonTefapCount += 1;
        nonTefapWeight += parseFloat(weight) || 0;
      }

      if (!donorCountData[donor]) {
        donorCountData[donor] = 0;
        donorWeightData[donor] = 0;
      }
      donorCountData[donor] += 1;
      donorWeightData[donor] += parseFloat(weight) || 0;
    });

    setData(Object.values(tempData));
    setTefapData({
      count: [{ name: 'TEFAP', value: tefapCount }, { name: 'Non-TEFAP', value: nonTefapCount }],
      weight: [{ name: 'TEFAP', value: tefapWeight }, { name: 'Non-TEFAP', value: nonTefapWeight }]
    });
    setPieChartData({
      count: Object.keys(donorCountData).map(donor => ({ name: donor, value: donorCountData[donor] })),
      weight: Object.keys(donorWeightData).map(donor => ({ name: donor, value: donorWeightData[donor] }))
    });
  };



  return (
    <div className="container">

      <Segment>
        <Form>
          <Form.Group widths='equal'>
            {/* Category Selection Dropdown */}
            <Form.Select
              fluid
              label='Category'
              options={[
                { key: 'all', text: 'All Categories', value: '' },
                ...categories.map(category => ({
                  key: category,
                  text: category,
                  value: category
                }))
              ]}
              placeholder='Select Category'
              value={selectedCategory}
              onChange={(_, { value }) => setSelectedCategory(value)}
            />
            {/* Start Date Input */}
            <Form.Input
              fluid
              label='Start Date'
              type='date'
              value={startDate || ''}
              onChange={(e) => setStartDate(e.target.value)}
            />
            {/* End Date Input */}
            <Form.Input
              fluid
              label='End Date'
              type='date'
              value={endDate || ''}
              onChange={(e) => setEndDate(e.target.value)}
            />
            {/* Apply Filters Button */}
          </Form.Group>
        </Form>
      </Segment>


      <div className="stats-container">
        {/* Bar Chart Container */}
        <div className="chart-container">
          <h2>Category Breakdown</h2>
          <ResponsiveContainer width="100%" height={400}>
            <BarChart data={filteredData}>
              <CartesianGrid strokeDasharray="3 3" />
              <XAxis dataKey="subCategory" />
              <YAxis yAxisId="left" orientation="left" stroke="#8FBC8F" />
              <YAxis yAxisId="right" orientation="right" stroke="#A9A9A9" />
              <Tooltip />
              <Legend />
              <Bar yAxisId="left" dataKey="weight" fill="#8FBC8F" name="Weight" />
              <Bar yAxisId="right" dataKey="quantity" fill="#A9A9A9" name="Quantity" />
            </BarChart>
          </ResponsiveContainer>
        </div>

        <div className="chart-container">
          <h2>TEFAP vs Non-TEFAP Items</h2>
          <Button.Group>
            <Button onClick={() => setTefapViewMode('count')} active={tefapViewMode === 'count'}>Count</Button>
            <Button onClick={() => setTefapViewMode('weight')} active={tefapViewMode === 'weight'}>Weight</Button>
          </Button.Group>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie data={tefapData[tefapViewMode] || []} dataKey="value" nameKey="name" cx="50%" cy="50%" outerRadius={120} fill="#8884d8" label={(props) => renderCustomLabelTefap(props, tefapViewMode)}>
                {tefapData[tefapViewMode]?.map((entry, index) => (
                  <Cell 
                  key={`cell-${index}`} 
                  fill={COLORS[index % COLORS.length]} />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>
        <div className="chart-container">
          <h2>Donor Item Breakdown</h2>
          <Button.Group>
            <Button onClick={() => setDonorViewMode('count')} active={donorViewMode === 'count'}>Count</Button>
            <Button onClick={() => setDonorViewMode('weight')} active={donorViewMode === 'weight'}>Weight</Button>
          </Button.Group>
          <ResponsiveContainer width="100%" height={400}>
            <PieChart>
              <Pie
                data={pieChartData[donorViewMode] || []} // Ensure there is fallback empty array
                dataKey="value"
                nameKey="name"
                cx="50%"
                cy="50%"
                outerRadius={120}
                fill="#8884d8"
                label={renderCustomLabel}
              >
                {pieChartData[donorViewMode]?.map((entry, index) => (
                  <Cell key={`cell-${index}`} fill={getDonorColor(entry.name)}  />
                ))}
              </Pie>
              <Tooltip />
              <Legend />
            </PieChart>
          </ResponsiveContainer>
        </div>

      </div>
    </div>
  );
}
export default Stats;