// Add.js 

import React, { useState, useEffect } from "react"; 
import { Button, Table, Segment, Form, Modal } from 'semantic-ui-react'; 
import { getDatabase, ref, onValue, set, remove, child, push, update, increment, get } from "firebase/database"; 



import './Add.css';


const Add = () => {

  const today = new Date().toLocaleDateString();
  const settime = new Date().toLocaleTimeString();
  const [showRemovedTefapTable, setShowRemovedTefapTable] = useState(false);
  const [showRemovedNonTefapTable, setShowRemovedNonTefapTable] = useState(false);
  const [removedItems, setRemovedItems] = useState([]);
  const [removedTefapItems, setRemovedTefapItems] = useState([]);
  const [itemName, setItemName] = useState('');
  const [quantity, setQuantity] = useState('');
  const [weight, setWeight] = useState('');
  const [category, setCategory] = useState('');
  const [otherCategory, setOtherCategory] = useState(''); 
  const [otherSubCategory, setOtherSubCategory] = useState(''); 
  const [subCategory, setSubCategory] = useState('');
  const [donor, setDonor] = useState('');
  const [tefap, setTefap] = useState(false);
  const [expirationDate, setExpirationDate] = useState('');
  const [date, setDate] = useState(today);
  const [time, setTime] = useState(settime);
  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [donors, setDonors] = useState({});  
  const [otherDonor, setOtherDonor] = useState(''); 
  const [showAddPopup, setShowAddPopup] = useState(false);
  const db = getDatabase();

  
  useEffect(() => {
    const db = getDatabase();
    const fetchRemovedItems = async () => {
      const removedRef = ref(db, 'removed');
      const removedTefapRef = ref(db, 'removed-tefap');

      onValue(removedRef, (snapshot) => {
        const items = [];
        snapshot.forEach(child => {
          items.push({ id: child.key, ...child.val() });
        });
        setRemovedItems(items);
      });

      onValue(removedTefapRef, (snapshot) => {
        const items = [];
        snapshot.forEach(child => {
          items.push({ id: child.key, ...child.val() });
        });
        setRemovedTefapItems(items);
      });
    };

    fetchRemovedItems();
  }, []);

  useEffect(() => {
    const donorsRef = ref(db, 'donors');
    onValue(donorsRef, (snapshot) => {
      const fetchedDonors = {};
      snapshot.forEach(childSnapshot => {
        fetchedDonors[childSnapshot.key] = childSnapshot.val();
      });
      setDonors(fetchedDonors);
    });
  }, [db]);

  const handleAddDonor = (donorName, color) => {
    if (!donors[donorName]) { 
      const newDonors = { ...donors, [donorName]: { color: color } }; 
      set(ref(db, `donors/${donorName}`), { color: color }); 
      setDonors(newDonors);
    }
  };

  const getRandomPastelColor = () => {
    const hue = Math.floor(Math.random() * 360); 
    const saturation = 70 + Math.random() * 30; 
    const lightness = 30 + Math.random() * 20; 

    return hslToHex(hue, saturation, lightness);
  };

  function hslToHex(h, s, l) {
    l /= 100;
    const a = s * Math.min(l, 1 - l) / 100;
    const f = n => {
      const k = (n + h / 30) % 12;
      const color = l - a * Math.max(Math.min(k - 3, 9 - k, 1), -1);
      return Math.round(255 * color).toString(16).padStart(2, '0'); 
    };
    return `#${f(0)}${f(8)}${f(4)}`;
  }

  const handlePermanentlyRemove = async (item, isTefap) => {
    const removedPath = isTefap ? 'removed-tefap' : 'removed';
    const itemRef = ref(db, `${removedPath}/${item.id}`);

    try {
      await remove(itemRef); 
      console.log(`Item ${item.id} permanently removed.`);
      const updateItems = isTefap ? removedTefapItems.filter(i => i.id !== item.id) : removedItems.filter(i => i.id !== item.id);
      isTefap ? setRemovedTefapItems(updateItems) : setRemovedItems(updateItems);
    } catch (error) {
      console.error(`Failed to permanently remove item ${item.id}:`, error);
    }
  };

  const handleUndoRemove = async (item, isTefap) => {
    const db = getDatabase();
    const removedPath = isTefap ? 'removed-tefap' : 'removed';
    const inventoryPath = 'inventory';
    const donorPath = 'donors';
    const removedDonorPath = 'removed-donors';
    const infoPath = 'info';
    const donorRef = ref(db, `${donorPath}/${item.donor}`);
    const removedDonorRef = ref(db, `${removedDonorPath}/${item.donor}`);
   

    try {
      
      const donorSnapshot = await get(donorRef);
      if (!donorSnapshot.exists()) {
        const removedDonorSnapshot = await get(removedDonorRef);
        if (removedDonorSnapshot.exists()) {
          
          await set(donorRef, removedDonorSnapshot.val());
          await remove(removedDonorRef); 
          console.log(`Donor ${item.donor} restored from removed-donors.`);
        } else {
          console.error(`Donor ${item.donor} does not exist in active or removed donors.`);
          return; 
        }
      }

      const updates = {};
      updates[`${inventoryPath}/${item.id}`] = item;  
      updates[`${removedPath}/${item.id}`] = null;  
      updates[`${infoPath}/inWeight`] = increment(item.weight);  
      updates[`${infoPath}/outWeight`] = increment(-item.weight);  
      await update(ref(db), updates);
      const updateItems = isTefap ? removedTefapItems.filter(i => i.id !== item.id) : removedItems.filter(i => i.id !== item.id);
      isTefap ? setRemovedTefapItems(updateItems) : setRemovedItems(updateItems);

      console.log("Item successfully re-added to inventory:", item.id);
    } catch (error) {
      console.error("Error in undoing removal:", error);
    }
  };






  const toggleShowRemovedTefapTable = () => {
    setShowRemovedTefapTable(!showRemovedTefapTable);

    if (!showRemovedTefapTable) {
      setShowRemovedNonTefapTable(false);
    }
  };

  const toggleShowRemovedNonTefapTable = () => {
    setShowRemovedNonTefapTable(!showRemovedNonTefapTable);
    if (!showRemovedNonTefapTable) {
      setShowRemovedTefapTable(false);
    }
  };

  const handleCategoryChange = (e) => {
    setCategory(e.target.value);
    setSubCategory('');
  }

  const handleSubCategoryChange = (e) => {
    setSubCategory(e.target.value);
  }

  const handleOtherCategoryChange = (e) => {
    setCategory('Other');
    setOtherCategory(e.target.value);
  }

  const handleOtherSubCategoryChange = (e) => {
    setSubCategory('Other');
    setOtherSubCategory(e.target.value);
  }

  const handleDonorChange = (e) => {
    setDonor(e.target.value);
    if (e.target.value === "Other") {
      setTefap(false); 
    } else if (e.target.value === "USDA - TEFAP") {
      setTefap(true);
    } else {
      setTefap(false);
    }
  };

  const handleOtherDonorChange = (e) => {
    setOtherDonor(e.target.value);;
  };

  const donorOptions = Object.keys(donors);



  const renderRemovedItemsTable = (items, isTefap) => (
    <Table celled>
      <Table.Header>
        <Table.Row>
          <Table.HeaderCell>Item Name</Table.HeaderCell>
          <Table.HeaderCell>Quantity</Table.HeaderCell>
          <Table.HeaderCell>Weight</Table.HeaderCell>
          <Table.HeaderCell>Category</Table.HeaderCell>
          <Table.HeaderCell>Subcategory</Table.HeaderCell>
          <Table.HeaderCell>Donor</Table.HeaderCell>
          <Table.HeaderCell>Date Added</Table.HeaderCell>
          <Table.HeaderCell>Undo Remove</Table.HeaderCell>
          <Table.HeaderCell></Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {items.map((item) => (
          <Table.Row key={item.id}>
            <Table.Cell>{item.itemName}</Table.Cell>
            <Table.Cell>{item.quantity}</Table.Cell>
            <Table.Cell>{item.weight} lbs</Table.Cell>
            <Table.Cell>{item.category}</Table.Cell>
            <Table.Cell>{item.subCategory}</Table.Cell>
            <Table.Cell>{item.donor}</Table.Cell>
            <Table.Cell>{item.date}</Table.Cell>
            <Table.Cell>
              <Button onClick={() => handleUndoRemove(item, isTefap)}>Undo</Button>
            </Table.Cell>
            <Table.Cell>
              <Button color='red' onClick={() => handlePermanentlyRemove(item, isTefap)}>Permanently Remove</Button>
            </Table.Cell>
          </Table.Row>
        ))}
      </Table.Body>
    </Table>
  );



  const color = getRandomPastelColor();

  const addItemSubmit = async (e) => {
    e.preventDefault();

    const finalDonor = donor === "Other" ? otherDonor.trim() : donor;

    if (!itemName.trim()) {
      setErrorMessage("Item name cannot be empty.");
      setShowErrorModal(true);
      return;
    }
    if (!quantity.trim() || quantity <= 0) {
      setErrorMessage("Quantity must be filled out and greater than zero.");
      setShowErrorModal(true);
      return;
    }
    if (!weight.trim() || weight <= 0) {
      setErrorMessage("Weight must be filled out and greater than zero.");
      setShowErrorModal(true);
      return;
    }
    if (!category) {
      setErrorMessage("Please select a category.");
      setShowErrorModal(true);
      return;
    }
    if (!subCategory && category !== 'Other') { 
      setErrorMessage("Please select a subcategory.");
      setShowErrorModal(true);
      return;
    }
    if (category === 'Other' && !otherCategory.trim()) {
      setErrorMessage("Please enter an 'Other' category.");
      setShowErrorModal(true);
      return;
    }
    if (subCategory === 'Other' && !otherSubCategory.trim()) {
      setErrorMessage("Please enter an 'Other' subcategory.");
      setShowErrorModal(true);
      return;
    }
    if (!donor || (donor === 'Other' && !otherDonor.trim())) {
      setErrorMessage("Please select or enter a donor.");
      setShowErrorModal(true);
      return;
    }

    if (finalDonor.toLowerCase() === "other") {
      setErrorMessage("Please provide a valid donor name. 'Other' is not allowed.");
      setShowErrorModal(true);
      return;
    }


    if (donor === "Other" && finalDonor && !donors[finalDonor]) {
      const color = getRandomPastelColor();
      handleAddDonor(finalDonor, color);
    }

    
    const trimmedItemName = itemName.trim();
    if (!trimmedItemName) {
      setErrorMessage("Item name cannot be empty.");
      setShowErrorModal(true);
      return;
    }

    
    const numericQuantity = Number(quantity);
    const numericWeight = Number(weight);

   
    if (numericQuantity <= 0 || numericWeight <= 0) {
      setErrorMessage("Quantity and Weight must be greater than zero.");
      setShowErrorModal(true);
      return;
    }

    if (donor === "Other" && !otherDonor.trim()) {
      setErrorMessage("Please provide a name for 'Other' donor.");
      setShowErrorModal(true);
      return;
    }


    if (!color) { 
      color = getRandomPastelColor();
      await set(ref(db, `donorColors/${finalDonor}`), color);
    }

    try {
      const dbRef = ref(getDatabase());
      const itemData = {
        itemName: trimmedItemName,
        quantity: numericQuantity,
        weight: numericWeight,
        category: category === 'Other' ? otherCategory : category,
        subCategory: subCategory === 'Other' ? otherSubCategory : subCategory,
        donor: donor === 'Other' ? otherDonor : finalDonor,
        tefap,
        expirationDate,
        date,
        time,
      };

      const newItemKey = push(child(dbRef, tefap ? 'tefap-inventory' : 'inventory')).key;
      const updates = {};
      updates['/info/inWeight'] = increment(itemData.weight);  
      updates['/inventory/' + newItemKey] = itemData;

      await update(dbRef, updates);
      setShowAddPopup(true);
      setTimeout(() => setShowAddPopup(false), 2000);

      const sessionId = localStorage.getItem('currentSessionId');
      if (sessionId) {
        const sessionActionsRef = ref(db, `sessions/${sessionId}/actions`);
        const newAction = {
          type: 'add',
          details: itemData,
          timestamp: new Date().toISOString(),
        };
        push(sessionActionsRef, newAction); 

        console.log(`${itemData.itemName} has been added to session log:`, sessionId, newAction);
      }

      
      setItemName('');
      setQuantity('');
      setWeight('');
      setCategory('');
      setSubCategory('');
      setDonor('');
      setTefap(false);
      setExpirationDate('');
      setDate(today);
      setTime(settime);
      setOtherCategory('');
      setOtherSubCategory('');
      setOtherDonor('');

    } catch (error) {
      console.error("Error adding item:", error);
    }
  };

  return (
    <div className="outer-container">

      <div className="form-section-header">
        <h3>Add Items </h3>
      </div>

      <Form onSubmit={addItemSubmit} className="Add-items-container">

        <Segment className='add-form ui form'>
          <div className="field">
            <label>Item Name:</label>
            <input type="text" name="itemname" autoComplete="on" value={itemName} onChange={(e) => setItemName(e.target.value)} />
          </div>

          <div className="field">
            <label>Quantity:</label>
            <input type="number" name="quantity" autoComplete="on" value={quantity} onChange={(e) => setQuantity(e.target.value)} min="0" />
          </div>

          <div className="field">
            <label>Weight (lbs):</label>
            <input type="number" name="weight" autoComplete="on" value={weight} onChange={(e) => setWeight(e.target.value)} min="0" step="0.01" />
          </div>

          <div className="field">
            <label>Category:</label>
            <select name="category" value={category} onChange={handleCategoryChange}>
              <option value="">Select Category </option>
              <option value="Produce">Produce (Fruits, Vegetables, Herbs, Mushrooms)</option>
              <option value="Grains">Grains (Bread, Rice, Hot/Cold Cereals and Pasta)</option>
              <option value="Dairy">Dairy (Milk, Cheese, Yogurt, Butter) </option>
              <option value="Proteins">Proteins (Meat, Fish, Eggs and Alternatives)</option>
              <option value="Non-perishables">Non-perishables (Canned Food, Dry Beans/Legumes, Canned Vegetables, Canned Fruit, Canned Meat)</option>
              <option value="Beverages">Beverages (Water, Juice, Tea) </option>
              <option value="Other">Other</option>
            </select>
          </div>

          {category === "Produce" && (
            <div className="field">
              <label>Subcategory:</label>
              <select name="subcategory" value={subCategory} onChange={handleSubCategoryChange}>
                <option value="">Select Subcategory</option>
                <option value="Fruits">Fruits</option>
                <option value="Vegetables">Vegetables</option>
                <option value="Herbs">Herbs</option>
                <option value="Mushrooms">Muschrooms</option>
                <option value="Other">Other</option>
              </select>
            </div>
          )}

          {category === "Grains" && (
            <div className="field">
              <label>Subcategory:</label>
              <select name="subcategory" value={subCategory} onChange={handleSubCategoryChange}>
                <option value="">Select Subcategory</option>
                <option value="Bread">Bread</option>
                <option value="Rice">Rice</option>
                <option value="Hot Cereals">Hot Cereals</option>
                <option value="Cold Cereals">Cold Cereals</option>
                <option value="Pasta">Pasta</option>
                <option value="Other">Other</option>
              </select>
            </div>
          )}

          {category === "Dairy" && (
            <div className="field">
              <label>Subcategory:</label>
              <select name="subcategory" value={subCategory} onChange={handleSubCategoryChange}>
                <option value="">Select Subcategory</option>
                <option value="Milk">Milk</option>
                <option value="Cheese">Cheese</option>
                <option value="Yogurt">Yogurt</option>
                <option value="Butter">Butter</option>
                <option value="Other">Other</option>
              </select>
            </div>
          )}

          {category === "Proteins" && (
            <div className="field">
              <label>Subcategory:</label>
              <select name="subcategory" value={subCategory} onChange={handleSubCategoryChange}>
                <option value="">Select Subcategory</option>
                <option value="Meat">Meat (Beef, Chicken, Pork)</option>
                <option value="Fish">Fish (Salmon, Tuna, Cod)</option>
                <option value="Eggs">Eggs</option>
                <option value="Nuts">Nuts</option>
                <option value="Alternatives">Alternatives (Tofu) </option>
                <option value="Other">Other</option>
              </select>
            </div>
          )}

          {category === "Non-perishables" && (
            <div className="field">
              <label>Subcategory:</label>
              <select name="subcategory" value={subCategory} onChange={handleSubCategoryChange}>
                <option value="">Select Subcategory</option>
                <option value="Canned Food">Canned Food</option>
                <option value="Dry Beans/Legumes">Dry Goods</option>
                <option value="Canned Vegetables">Canned Vegetables</option>
                <option value="Canned Fruit">Canned Fruit</option>
                <option value="Canned Meat">Canned Meat</option>
                <option value="Other">Other</option>
              </select>
            </div>
          )}

          {category === "Beverages" && (
            <div className="field">
              <label>Subcategory:</label>
              <select name="subcategory" value={subCategory} onChange={handleSubCategoryChange}>
                <option value="">Select Subcategory</option>
                <option value="Juice">Juice</option>
                <option value="Water">Water</option>
                <option value="Tea">Tea</option>
                <option value="Other">Other</option>
              </select>
            </div>
          )}

          {category === "Other" && (
            <div className="field">
              <label>Other Category:</label>
              <input type="text" name="otherCategory" autoComplete="on" value={otherCategory} onChange={handleOtherCategoryChange} />
            </div>
          )}

          {subCategory === "Other" && (
            <div className="field">
              <label>Other Subcategory:</label>
              <input type="text" name="otherSubCategory" autoComplete="on" value={otherSubCategory} onChange={handleOtherSubCategoryChange} />
            </div>
          )}

          <div className="field">
            <label>Donor:</label>
            <select name="donor" value={donor} onChange={handleDonorChange}>
              <option value="" disabled>Select Donor</option>
              {donorOptions.map(donorName => (
                <option key={donorName} value={donorName}>{donorName}</option>
              ))}
              <option value="Other">Other</option>
            </select>
            {donor === "Other" && (
              <input
                type="text"
                placeholder="Enter new donor name"
                value={otherDonor}
                onChange={handleOtherDonorChange}
                required
              />
            )}
          </div>


          <div className="field">
            <label>Expiration Date:</label>
            <input type="date" value={expirationDate} onChange={(e) => setExpirationDate(e.target.value)} />
          </div>

          <div className="field">
            <label>Date:</label>
            <input type="text" value={date} onChange={(e) => setDate(e.target.value)} />
          </div>

          <div className="field">
            <label>Time:</label>
            <input type="text" value={time} onChange={(e) => setTime(e.target.value)} />
          </div>

          <button type="submit" className="ui green button large">Add Item</button>
          {showAddPopup && (
            <div className="success-popup">
              Item successfully added!
            </div>
          )}

        </Segment>
      </Form>

      <Modal open={showErrorModal} onClose={() => setShowErrorModal(false)} size='small'>
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>
          <p style={{ marginTop: '20px', marginBottom: '10px' }}>{errorMessage} </p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowErrorModal(false)}>Close</Button>
        </Modal.Actions>
      </Modal>

      <div className="undo-buttons-container">
        <Button onClick={toggleShowRemovedTefapTable} className="ui button" type="button"> View Removed TEFAP Items</Button>
        <Button onClick={toggleShowRemovedNonTefapTable} className="ui button" type="button"> View Removed Items</Button>
      </div>

      {showRemovedTefapTable && (
        <div className="removed-items-table">
          <h2>Removed TEFAP Items</h2>
          {renderRemovedItemsTable(removedTefapItems, true)}
        </div>
      )}

      {showRemovedNonTefapTable && (
        <div className="removed-items-table">
          <h2>Removed Non-TEFAP Items</h2>
          {renderRemovedItemsTable(removedItems, false)}
        </div>
      )}



    </div>
  );
}

export default Add;
