// Login.js
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Popup, Icon } from "semantic-ui-react"; 

import "./Login.css";

const Login = () => {
  const navigate = useNavigate();
  const [isOpen, setIsOpen] = useState(false);

  const navigateToAdminLogin = () => {
    navigate("/admin-Login");
  };

  const navigateToVolunteerLogin = () => {
    navigate("/volunteer-Login");
  };

  const handleTooltipToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    
    <div className="login-container">
      <div className="login-header">
        <img src="./CashmereFB.png" alt="Cashmere Food Bank Logo" />
        <h1>Welcome to the Inventory Management Dashboard</h1>
        <p>
          Mission: To provide free, nutritious food to all people seeking assistance
        </p>
        <p>Vision: To eliminate hunger in the greater Cashmere area</p>
      </div>
      <div className="role-selection">
        <Button onClick={navigateToAdminLogin}>Admin Login</Button>
        <Button onClick={navigateToVolunteerLogin}>Volunteer Login</Button>
      </div>
      <div className="tooltip">
        <Popup
          content={
            <>
              In collaboration with Washington State University, this project was built by {" "}
              <a href="https://www.linkedin.com/in/zayn-abou-harb/" target="_blank">Zayn</a>, {" "}
              <a href="https://www.linkedin.com/in/sabri-tahir/" target="_blank">Sabri</a>, and {" "}
              <a href="https://www.linkedin.com/in/mark-shinozaki-%E7%AF%A0%E5%B4%8E-372699b7/" target="_blank">Mark </a>.
            </>
          }
          trigger={<Icon name="info circle" size="big" />}
          on="click"
          open={isOpen}
          onClose={handleTooltipToggle}
          onOpen={handleTooltipToggle}
          position="bottom center"
        />
      </div>
    </div>
  );
};

export default Login;
