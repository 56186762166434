// ProtectedRoute.Js
import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';
import { useAuth } from '../../AuthContext';
import Header from '../CoreComponents/Header';


const ProtectedRoute = ({ children }) => {
  const { currentUser } = useAuth();
  
  
  const location = useLocation();


  if (!currentUser) {
    return <Navigate to="/login" state={{ from: location }} replace />;
  }
  
  return (
    <>
      <Header />
      {children}
    </>
  );
};


export default ProtectedRoute;
