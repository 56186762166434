// Settings.js 

import { useNavigate } from 'react-router-dom';
import React, { useState, useEffect } from 'react';
import { signOut as firebaseSignOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { useAuth } from '../../AuthContext';
import { Button, Modal, Popup, Form } from 'semantic-ui-react';
import { getDatabase, ref, get, set, onValue, remove, push, update } from 'firebase/database';
import { Table } from 'semantic-ui-react';
import { getAuth, createUserWithEmailAndPassword, sendPasswordResetEmail, fetchSignInMethodsForEmail } from "firebase/auth";




import './Settings.css';

const Settings = () => {

  const navigate = useNavigate();
  const { signOut } = useAuth();
  const [sessions, setSessions] = useState([]);
  const [users, setUsers] = useState([]);
  const [showDeleteConfirmModal, setShowDeleteConfirmModal] = useState(false);
  const [activeSessionDetails, setActiveSessionDetails] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [showNoActivityTooltip, setShowNoActivityTooltip] = useState(false);
  const [showRoleUpdateConfirmModal, setShowRoleUpdateConfirmModal] = useState(false);

  const [showUsersModal, setShowUsersModal] = useState(false);
  const [showAddUserModal, setShowAddUserModal] = useState(false);
  const [UserEmail, setUserEmail] = useState('');
  const [newUserPassword, setNewUserPassword] = useState('');
  const [newUserRole, setNewUserRole] = useState('volunteer');
  const [showConfirmModal, setShowConfirmModal] = useState(false);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [donors, setDonors] = useState([]);

  const [showDonorsModal, setShowDonorsModal] = useState(false);
  const [donorToDelete, setDonorToDelete] = useState(null);

  const [showDeleteDonorConfirm, setShowDeleteDonorConfirm] = useState(false);
  const [userToRemove, setUserToRemove] = useState(null);
  const [showConfirmRemovalModal, setShowConfirmRemovalModal] = useState(false);
  const [showCannotDeleteModal, setShowCannotDeleteModal] = useState(false);
  const [cannotDeleteMessage, setCannotDeleteMessage] = useState('');

  const [showErrorModal, setShowErrorModal] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');

  const [topAdminUid, setTopAdminUid] = useState(null);
  const [showRoleChangeNotification, setShowRoleChangeNotification] = useState(false);
  const [roleChangeDetails, setRoleChangeDetails] = useState({ email: '', oldRole: '', newRole: '' });

  const [deletableDonors, setDeletableDonors] = useState({});

  const [showTransferTopAdminModal, setShowTransferTopAdminModal] = useState(false);
  const [newTopAdminUid, setNewTopAdminUid] = useState('');




  const getCurrentSessionId = () => localStorage.getItem('currentSessionId');


  useEffect(() => {
    const db = getDatabase();
    const sessionsRef = ref(db, 'sessions');
    onValue(sessionsRef, (snapshot) => {
      if (snapshot.exists()) {
        const allSessions = Object.entries(snapshot.val()).map(([key, value]) => ({
          sessionId: key,
          userType: value.userType,
          userEmail: value.userEmail,
          startTime: value.startTime,
          endTime: value.endTime || 'Session Active',
          actions: value.actions ? Object.values(value.actions) : [],
        }));

        const sortedSessions = allSessions.sort((a, b) => a.endTime === 'Session Active' ? -1 : new Date(b.endTime) - new Date(a.endTime));
        setSessions(sortedSessions);
      } else {
        setSessions([]);
      }
    });
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const topAdminRef = ref(db, 'Top Admin/uid');
    onValue(topAdminRef, (snapshot) => {
      if (snapshot.exists()) {
        setTopAdminUid(snapshot.val());
      } else {
        console.log("No Top Admin defined");
      }
    });
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const usersRef = ref(db, 'users');
    onValue(usersRef, (snapshot) => {
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        let usersList = [];
        let topAdminUid = null;

        Object.keys(usersData).forEach((key) => {
          const user = usersData[key];
          if (user) {
            usersList.push({
              uid: key,
              ...user,
              tempRole: user.role,
            });
            if (user.role === "Top Admin") {
              topAdminUid = key;
            }
          }
        });

        setUsers(usersList);
        setTopAdminUid(topAdminUid);
      } else {
        console.log("No users found.");
        setUsers([]);
        setTopAdminUid(null);
      }
    });
  }, []);



  useEffect(() => {
    const db = getDatabase();
    const donorsRef = ref(db, 'donors');
    onValue(donorsRef, (snapshot) => {
      if (snapshot.exists()) {
        const fetchedDonors = [];
        snapshot.forEach(snap => {
          const colorInfo = snap.val().color;
          fetchedDonors.push({
            name: snap.key,
            color: colorInfo
          });
        });
        setDonors(fetchedDonors);
      } else {
        setDonors([]);
      }
    });
  }, []);

  useEffect(() => {
    const db = getDatabase();
    const inventoryRef = ref(db, 'inventory');
    const donorsRef = ref(db, 'donors');

    const fetchDonorsAndInventory = async () => {
      const [donorsSnapshot, inventorySnapshot] = await Promise.all([
        get(donorsRef),
        get(inventoryRef)
      ]);

      let inventoryByDonor = {};
      if (inventorySnapshot.exists()) {
        inventoryByDonor = Object.values(inventorySnapshot.val() || {}).reduce((acc, item) => {
          acc[item.donor] = [...(acc[item.donor] || []), item];
          return acc;
        }, {});
      }

      if (donorsSnapshot.exists()) {
        const fetchedDonors = [];
        const deletableChecks = {};
        donorsSnapshot.forEach(snap => {
          const colorInfo = snap.val().color;
          fetchedDonors.push({
            name: snap.key,
            color: colorInfo
          });

          const items = inventoryByDonor[snap.key] || [];
          const canDelete = items.every(item => item.status === 'removed');
          deletableChecks[snap.key] = canDelete;
        });
        setDonors(fetchedDonors);
        setDeletableDonors(deletableChecks);
      }
    };

    fetchDonorsAndInventory();


    const unsubscribe = onValue(inventoryRef, () => {
      fetchDonorsAndInventory();
    });

    return () => unsubscribe();
  }, []);





  useEffect(() => {
    const db = getDatabase();
    const usersRef = ref(db, 'users');
    onValue(usersRef, (snapshot) => {
      if (snapshot.exists()) {
        const usersData = snapshot.val();
        let usersList = [];
        let topAdminUid = null;

        Object.keys(usersData).forEach((key) => {
          const user = {
            uid: key,
            ...usersData[key],
            tempRole: usersData[key].role,
          };
          usersList.push(user);

          if (user.role === "Top Admin") {
            topAdminUid = key;
          }
        });


        if (topAdminUid) {
          usersList = [
            ...usersList.filter(user => user.uid === topAdminUid),
            ...usersList.filter(user => user.uid !== topAdminUid),
          ];
          setTopAdminUid(topAdminUid);
        }

        setUsers(usersList);
      } else {
        setUsers([]);
        setTopAdminUid(null);
      }
    });
  }, []);


  useEffect(() => {
    const handleTabClose = async (event) => {
      event.preventDefault();
      event.returnValue = '';
      try {
        await endSession();
        await firebaseSignOut(auth);
        signOut();
      } catch (error) {
        console.error('Error signing out on tab close:', error);
      }
    };

    window.addEventListener('beforeunload', handleTabClose);
    return () => window.removeEventListener('beforeunload', handleTabClose);
  }, [signOut]);

  const handleSignOut = async () => {
    try {
      await firebaseSignOut(auth);
      await endSession();
      signOut();
      console.log("Signed out successfully");
      navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };




  const toggleUsersModal = () => setShowUsersModal(!showUsersModal);
  const toggleAddUserModal = () => setShowAddUserModal(!showAddUserModal);

  const logSessionAction = async (actionType, details) => {
    const sessionId = getCurrentSessionId();
    if (sessionId) {
      const db = getDatabase();
      const sessionActionsRef = ref(db, `sessions/${sessionId}/actions`);
      const newAction = {
        type: actionType,
        details,
        timestamp: new Date().toISOString(),
      };
      await push(sessionActionsRef, newAction);
      console.log(`Logged ${actionType}:`, newAction);
    }
  };


  const deleteDonor = async (donor) => {
    if (donor) {
      const db = getDatabase();
      const donorRef = ref(db, `donors/${donor.name}`);
      const removedDonorRef = ref(db, `removed-donors/${donor.name}`);

      try {

        const donorSnapshot = await get(donorRef);
        if (donorSnapshot.exists()) {

          await set(removedDonorRef, donorSnapshot.val());
          await remove(donorRef);
          console.log('Donor removed and moved to removed-donors:', donor.name);
          setDonors(donors.filter(d => d.name !== donor.name));
          setShowDeleteDonorConfirm(false);
          const colorInfo = donorSnapshot.val().color;
          logSessionAction('remove_donor', { donorName: donor.name, color: colorInfo });
        } else {
          console.error('Donor does not exist:', donor.name);
        }
      } catch (error) {
        console.error('Failed to remove donor:', error);
        setCannotDeleteMessage(`Failed to remove donor ${donor.name}. Error: ${error.message}`);
        setShowCannotDeleteModal(true);
      }
    }
  };


  const canDeleteDonor = async (donorName) => {
    const db = getDatabase();
    const inventoryRef = ref(db, 'inventory');
    const snapshot = await get(inventoryRef);
    let canDelete = true;

    if (snapshot.exists()) {
      const items = snapshot.val();

      Object.keys(items).forEach(key => {
        if (items[key].donor === donorName && items[key].status !== 'removed') {
          canDelete = false;
        }
      });
    }

    return canDelete;
  };

  const handleDeleteDonor = async (donor) => {

    const canDelete = await canDeleteDonor(donor.name);
    if (canDelete) {
      deleteDonor(donor);
    }
  };

  const deleteAllSessions = async () => {
    const db = getDatabase();
    const sessionsRef = ref(db, 'sessions');

    try {
      await set(sessionsRef, null);
      console.log('All sessions have been successfully deleted.');
      setSessions([]);
      navigate('/login');
    } catch (error) {
      console.error('Error deleting sessions:', error);
    }
  };

  const endSession = async () => {
    const sessionId = localStorage.getItem('currentSessionId');
    const db = getDatabase();
    const sessionsRef = ref(db, 'sessions');

    const sessionsSnapshot = await get(sessionsRef);
    if (sessionsSnapshot.exists()) {
      const sessions = sessionsSnapshot.val();
      for (const [key, session] of Object.entries(sessions)) {
        if (!session.endTime && session.deviceIdentifier === getDeviceIdentifier()) {
          const sessionToUpdateRef = ref(db, `sessions/${key}`);
          await set(sessionToUpdateRef, { ...session, endTime: new Date().toISOString() });
          console.log(`Ended previous active session: ${key}`);
        }
      }
    }

    if (!sessionId) {
      console.error("No session ID found for ending session.");
      return;
    }

    function getDeviceIdentifier() {
      let deviceIdentifier = localStorage.getItem('deviceIdentifier');
      if (!deviceIdentifier) {
        deviceIdentifier = `${navigator.userAgent}-${Math.random().toString(36).substring(2, 15)}`;
        localStorage.setItem('deviceIdentifier', deviceIdentifier);
      }
      return deviceIdentifier;
    }

    const sessionEnd = new Date();
    const sessionRef = ref(db, `sessions/${sessionId}`);
    const sessionSnapshot = await get(sessionRef);
    if (sessionSnapshot.exists()) {
      await set(sessionRef, {
        ...sessionSnapshot.val(),
        endTime: sessionEnd.toISOString(),
      });
      console.log("Current session ended:", sessionId, "at", sessionEnd.toLocaleString());
    }

    localStorage.removeItem('currentSessionId');
  };

  const handleOverviewClick = (sessionId) => {
    const sessionDetails = sessions.find(session => session.sessionId === sessionId);

    if (sessionDetails && sessionDetails.actions && sessionDetails.actions.length > 0) {
      setActiveSessionDetails(sessionDetails);
      setShowModal(true);
      setShowNoActivityTooltip(false);
    } else {
      setShowNoActivityTooltip(true);
      setTimeout(() => setShowNoActivityTooltip(false), 3000);
    }
  };


  const updateUserRole = async (uid, newRole) => {
    if (!uid) {
      console.error("UID is null or undefined");
      return;
    }
    if (auth.currentUser?.uid !== topAdminUid) {
      console.error("Unauthorized attempt to change roles by non-Top Admin");
      return;
    }
    if (auth.currentUser.uid !== topAdminUid) {
      console.error("Unauthorized attempt to change roles by non-Top Admin");
      return;
    }

    const db = getDatabase();
    const userRef = ref(db, `users/${uid}`);

    try {
      const userSnapshot = await get(userRef);
      if (!userSnapshot.exists()) {
        throw new Error('User does not exist.');
      }

      const currentRole = userSnapshot.val().role;
      const userEmail = userSnapshot.val().email;

      if (newRole === "Top Admin" && currentRole !== "Top Admin") {
        setNewTopAdminUid(uid);
        setShowTransferTopAdminModal(true);
        return;
      }

      await set(ref(db, `users/${uid}/role`), newRole);
      logSessionAction('update_user_role', { uid, email: userEmail, prevRole: currentRole, newRole });
      setRoleChangeDetails({ email: userEmail, oldRole: currentRole, newRole });
      setShowRoleChangeNotification(true);

    } catch (error) {
      console.error('Error updating user role:', error);
      setErrorMessage(`Failed to update role: ${error.message}`);
      setShowErrorModal(true);
    }
  };

  const handlePreAddUser = () => {
    setShowConfirmModal(true);
  };


  const confirmUserRemoval = (uid) => {
    const currentUser = users.find(user => user.uid === auth.currentUser.uid);
    const userToRemove = users.find(user => user.uid === uid);

    if (!currentUser || !userToRemove) {
      console.error('User details not found.');
      setErrorMessage('User details not found.');
      setShowErrorModal(true);
      return;
    }


    if (currentUser.role === 'Top Admin' && userToRemove.role === 'Admin') {
      setUserToRemove(uid);
      setShowConfirmRemovalModal(true);
    } else if (currentUser.role === 'Admin' && userToRemove.role === 'Volunteer') {

      setUserToRemove(uid);
      setShowConfirmRemovalModal(true);
    } else {
      console.error('Unauthorized attempt to remove user.');
      setErrorMessage('You do not have permission to remove this user.');
      setShowErrorModal(true);
    }
  };


  const removeUser = async () => {
    if (userToRemove) {
      const db = getDatabase();
      const userRef = ref(db, `users/${userToRemove}`);
      const userSnapshot = await get(userRef);

      if (userSnapshot.exists()) {
        await remove(userRef);
        console.log('User removed:', userToRemove);


        setUsers(users.filter(user => user.uid !== userToRemove));
        logSessionAction('remove_user', { uid: userToRemove, email: userSnapshot.val().email });
        setUserToRemove(null);
        setShowConfirmRemovalModal(false);
      } else {
        console.error('Error removing user:', userToRemove);
        setErrorMessage(`Failed to remove user: User does not exist.`);
        setShowErrorModal(true);
      }
    }
  };


  const confirmTopAdminTransfer = async () => {
    if (!topAdminUid || !newTopAdminUid) {
      console.error("Missing UIDs for Top Admin transfer:", { topAdminUid, newTopAdminUid });
      return;
    }

    const db = getDatabase();
    const updates = {};
    updates[`users/${topAdminUid}/role`] = 'Admin';
    updates[`users/${newTopAdminUid}/role`] = 'Top Admin';

    try {
      await update(ref(db), updates);
      console.log("Top Admin transferred from", topAdminUid, "to", newTopAdminUid);
      await firebaseSignOut(auth);
      signOut();
      navigate('/login', { replace: true });
    } catch (error) {
      console.error("Error during Top Admin transfer:", error);
      setErrorMessage(`Error transferring Top Admin: ${error.message}`);
    }
  };


  const createUser = async () => {
    setShowConfirmModal(false);

    try {

      const userCredential = await createUserWithEmailAndPassword(auth, UserEmail, "temporaryPassword123");
      const user = userCredential.user;

      await sendPasswordResetEmail(auth, UserEmail, {
        url: 'https://cashmerefoodbank-d92b3.firebaseapp.com/__/auth/action',
        handleCodeInApp: true,
      });

      const db = getDatabase();
      await set(ref(db, `users/${user.uid}`), {
        email: UserEmail,
        role: newUserRole,
        dateCreated: new Date().toISOString(),
      });

      const sessionId = getCurrentSessionId();
      const sessionActionsRef = ref(db, `sessions/${sessionId}/actions`);
      const newAction = {
        type: 'add_user',
        details: {
          email: UserEmail,
          role: newUserRole
        },
        timestamp: new Date().toISOString(),
      };
      await push(sessionActionsRef, newAction);


      setShowSuccessModal(true);
    } catch (error) {
      console.error("Error adding new user:", error);
      setErrorMessage(`Failed to add user: ${error.message}`);
      setShowErrorModal(true);
    } finally {

      setShowAddUserModal(false);
      setUserEmail('');
      setNewUserRole('volunteer');
    }
  };

  function hashCode(str) {
    let hash = 0;
    for (let i = 0; i < str.length; i++) {
      hash = str.charCodeAt(i) + ((hash << 5) - hash);
    }
    return Math.abs(hash);
  }

  const generateColorButton = (sessionId) => {
    const colors = ['#8FBC8F', '#F08080', 'BurlyWood', '#20B2AA', 'gray', 'black'];

    const colorIndex = hashCode(sessionId) % colors.length;

    const color = colors[colorIndex];

    return (
      <Popup
        trigger={<Button className="color-button" style={{ backgroundColor: color }}></Button>}
        content={sessionId}
        position='top center'
        inverted
      />
    );
  };

  const resetFormAndCloseModal = () => {
    setUserEmail('');
    setNewUserRole('volunteer');
    setShowAddUserModal(false);
  };


  return (

    <div className="settings-container">
      <div className="admin-controls-container">
        <h1>Admin Controls</h1>
        <div className="admin-buttons">
          <Button className="check-users-button" onClick={toggleUsersModal}>Check Users</Button>
          <Button className="manage-donors-button" onClick={() => setShowDonorsModal(true)}>Manage Donors</Button>

          <Button className="add-users-button" onClick={toggleAddUserModal} style={{ backgroundColor: 'rgb(143, 185, 143)', color: 'white' }}>Add New Users</Button>

          <Button className="sign-out-button" onClick={handleSignOut}>Sign Out</Button>
        </div>
      </div>

      <h1>Session Data</h1>
      <Table celled structured>
        <Table.Header>
          <Table.Row>
            <Table.HeaderCell>Session Date</Table.HeaderCell>
            <Table.HeaderCell>Session ID</Table.HeaderCell>
            <Table.HeaderCell>Email</Table.HeaderCell>
            <Table.HeaderCell>User Type</Table.HeaderCell>
            <Table.HeaderCell>Start Time</Table.HeaderCell>
            <Table.HeaderCell>End Time</Table.HeaderCell>
            <Table.HeaderCell>Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sessions.map(session => (
            <Table.Row key={session.sessionId}>
              <Table.Cell>{new Date(session.startTime).toLocaleDateString()}</Table.Cell>
              <Table.Cell>{generateColorButton(session.sessionId)}</Table.Cell>
              <Table.Cell>{session.userEmail || 'N/A'}</Table.Cell>
              <Table.Cell>{session.userType}</Table.Cell>

              <Table.Cell>{new Date(session.startTime).toLocaleTimeString()}</Table.Cell>
              <Table.Cell>
                {session.endTime === 'Session Active' ?
                  <span className="session-active">{session.endTime}</span> :
                  session.endTime ? new Date(session.endTime).toLocaleTimeString() : 'N/A'}
              </Table.Cell>

              <Table.Cell>
                {session.actions && session.actions.length > 0 ? (
                  <Button onClick={() => handleOverviewClick(session.sessionId)}>Overview</Button>
                ) : (
                  <Popup
                    trigger={<Button style={{ opacity: 0.4 }}>Overview</Button>}
                    content="No activity recorded during this session."
                    position="right center" />
                )}
              </Table.Cell>
            </Table.Row>
          ))}
        </Table.Body>
      </Table>


      {showModal && (
        <Modal open={showModal} onClose={() => setShowModal(false)} scrollable size='large' className='modal-content'>
          <Modal.Header>Session Overview: {activeSessionDetails?.sessionId}</Modal.Header>
          <Modal.Content scrolling>
            {activeSessionDetails?.actions.length > 0 ? (
              activeSessionDetails?.actions.map((action, index) => (
                <div key={index}>
                  <p>Type of Action: {action.type}</p>
                  <div>
                    {action.type === 'remove_donor' ? (
                      <div style={{ marginLeft: '20px' }}>
                        <p>Donor Name: {action.details.donorName}</p>
                        <p>Color: {action.details.color}</p>
                      </div>
                    ) : action.type === 'removeWeight' ? (
                      <div style={{ marginLeft: '20px' }}>
                        <p>Weight Removed: {action.details.removedWeight.toFixed(2)} lbs</p>
                      </div>
                    ) : action.type === 'update_user_role' ? (
                      <div style={{ marginLeft: '20px' }}>
                        <p>User ID: {action.details.uid}</p>
                        <p>Email: {action.details.email}</p>
                        <p>Previous Role: {action.details.prevRole}</p>
                        <p>New Role: {action.details.newRole}</p>
                      </div>
                    ) : action.type === 'remove_user' ? (
                      <div style={{ marginLeft: '20px' }}>
                        <p>User ID: {action.details.uid}</p>
                        <p>Email: {action.details.email}</p>
                        <p>Message: User removed from the system</p>
                      </div>
                    ) : action.type === 'add_user' ? (
                      <div style={{ marginLeft: '20px' }}>
                        <p>New User Email: {action.details.email}</p>
                        <p>User Role: {action.details.role}</p>
                      </div>
                    ) : action.type === 'remove' ? (

                      <div style={{ marginLeft: '20px' }}>
                        <p>Item: {action.details.itemName}</p>
                        <p>Quantity: {action.details.quantity}</p>
                        <p>Weight: {action.details.weight} lbs</p>
                        <p>Category: {action.details.category}</p>
                        <p>Subcategory: {action.details.subCategory}</p>
                        <p>Donor: {action.details.donor}</p>
                        <p>Expiration Date: {action.details.expirationDate}</p>
                        <p>Date Added: {action.details.date}</p>
                        <p>Time Added: {action.details.time}</p>
                        <p>Removal Date: {new Date(action.details.removalDate).toLocaleString()}</p>
                      </div>
                    ) : (
                      <div style={{ marginLeft: '20px' }}>
                        <p>Item: {action.details.itemName}</p>
                        <p>Quantity: {action.details.quantity}</p>
                        <p>Weight: {action.details.weight} lbs</p>
                        <p>Category: {action.details.category}</p>
                        <p>Subcategory: {action.details.subCategory}</p>
                        <p>Donor: {action.details.donor}</p>
                        <p>Expiration Date: {action.details.expirationDate}</p>
                        <p>Date Added: {action.details.date}</p>
                        <p>Time Added: {action.details.time}</p>
                      </div>
                    )}
                  </div>
                  {index !== activeSessionDetails.actions.length - 1 && <hr />}
                </div>
              ))
            ) : (
              <p>There was no activity for this session.</p>
            )}
          </Modal.Content>
          <Modal.Actions>
            <Button onClick={() => setShowModal(false)}>Close</Button>
          </Modal.Actions>
        </Modal>
      )}


      <Modal
        open={showDeleteConfirmModal}
        onClose={() => setShowDeleteConfirmModal(false)}
        size='small'
      >
        <Modal.Header>Delete All Sessions</Modal.Header>
        <Modal.Content className='modal-content-delete-sessions'>
          <p style={{ marginTop: '20px', marginBottom: '10px' }}>Are you sure you want to delete all session data?</p>
          <p style={{ marginBottom: '20px' }}>This action cannot be undone.</p>
        </Modal.Content>

        <Modal.Actions style={{ display: 'flex', justifyContent: 'space-between' }}>
          <Button negative onClick={() => setShowDeleteConfirmModal(false)}>No</Button>
          <Button positive onClick={() => { deleteAllSessions(); setShowDeleteConfirmModal(false); }} style={{ backgroundColor: 'rgb(143, 185, 143)', color: 'white' }}>Yes, Delete All</Button>
        </Modal.Actions>
      </Modal>
      <Button className="delete-sessions" onClick={() => setShowDeleteConfirmModal(true)} color='red'>Delete All Sessions</Button>

      <Modal open={showDonorsModal} onClose={() => setShowDonorsModal(false)}>
        <Modal.Header> Manage Donors</Modal.Header>
        <Modal.Content className='manage-donors'>
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>Name</Table.HeaderCell>
                <Table.HeaderCell>Color</Table.HeaderCell>
                <Table.HeaderCell className="centered-cell">Remove Donor</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {donors.map((donor) => (
                <Table.Row key={donor.name}>
                  <Table.Cell>{donor.name}</Table.Cell>
                  <Table.Cell style={{ backgroundColor: donor.color }}>{donor.color}</Table.Cell>
                  <Table.Cell className="flex-center">
                    <Button
                      color='red'
                      disabled={!deletableDonors[donor.name]}
                      onClick={() => handleDeleteDonor(donor)}
                      style={{
                        backgroundColor: deletableDonors[donor.name] ? 'red' : 'grey',
                        color: deletableDonors[donor.name] ? 'white' : 'darkgrey'
                      }}
                    >
                      Remove
                    </Button>
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
          </Table>
        </Modal.Content>
      </Modal>



      <Modal open={showConfirmRemovalModal} onClose={() => setShowConfirmRemovalModal(false)}>
        <Modal.Header>Confirm Removal</Modal.Header>
        <Modal.Content>
          <p style={{ marginTop: '20px', marginBottom: '10px' }}>
            Are you sure you want to remove this user?
          </p>
          <p>
            Removing them will delete their account and they cannot be re-added with the same credentials.
            You will need to create a new account with a new email if you want them back in the system.
          </p>
        </Modal.Content>
        <Modal.Actions style={{ display: 'flex', justifyContent: 'space-between' }} >
          <Button onClick={() => setShowConfirmRemovalModal(false)}>No</Button>
          <Button color='red' onClick={removeUser}>Yes, Remove</Button>
        </Modal.Actions>
      </Modal>

      <Modal open={showUsersModal} onClose={toggleUsersModal}>
        <Modal.Header>Current Users</Modal.Header>
        <Modal.Content className="modal-users-content">
          <Table celled>
            <Table.Header>
              <Table.Row>
                <Table.HeaderCell>UID</Table.HeaderCell>
                <Table.HeaderCell>Email</Table.HeaderCell>
                <Table.HeaderCell>Role</Table.HeaderCell>
                <Table.HeaderCell>Remove User</Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {users.map((user) => (
                <Table.Row key={user.uid}>
                  <Table.Cell>{user.uid}</Table.Cell>
                  <Table.Cell>{user.email}</Table.Cell>
                  <Table.Cell>
                    {user.role === "Top Admin" ? (
                      <span>Top Admin</span>
                    ) : (
                      <select
                        value={user.role}
                        onChange={(e) => updateUserRole(user.uid, e.target.value)}

                        disabled={user.uid === auth.currentUser.uid || (auth.currentUser.uid !== topAdminUid && user.role === "Top Admin")}
                      >
                        <option value="Admin">Admin</option>
                        <option value="Volunteer">Volunteer</option>
                        {auth.currentUser.uid === topAdminUid && <option value="Top Admin">Top Admin</option>}
                      </select>
                    )}
                  </Table.Cell>
                  <Table.Cell>
                    {user.uid !== topAdminUid && (
                      <Button color='red' onClick={() => confirmUserRemoval(user.uid)}>
                        Remove
                      </Button>
                    )}
                  </Table.Cell>
                </Table.Row>
              ))}
            </Table.Body>
            <Modal open={showRoleChangeNotification} onClose={() => setShowRoleChangeNotification(false)} size='small'>
              <Modal.Header>Role Update Notification</Modal.Header>
              <Modal.Content>
                <p>{`${roleChangeDetails.email}'s role has been updated from ${roleChangeDetails.oldRole} to ${roleChangeDetails.newRole}.`}</p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setShowRoleChangeNotification(false)}>OK</Button>
              </Modal.Actions>
            </Modal>

            <Modal open={showTransferTopAdminModal} onClose={() => setShowTransferTopAdminModal(false)} size='small'>
              <Modal.Header>Confirm Top Admin Transfer</Modal.Header>
              <Modal.Content>
                <p>Are you sure you want to transfer Top Admin status to this user? This action will revoke your Top Admin privileges.</p>
              </Modal.Content>
              <Modal.Actions>
                <Button onClick={() => setShowTransferTopAdminModal(false)}>Cancel</Button>
                <Button onClick={confirmTopAdminTransfer}>Confirm Transfer</Button>
              </Modal.Actions>
            </Modal>






          </Table>
        </Modal.Content>
      </Modal>

      <div style={{ marginBottom: '40px' }}></div>

      {/* Modal for adding a new user */}
      <Modal open={showAddUserModal} onClose={resetFormAndCloseModal}>
        <Modal.Header>Add New Users</Modal.Header>
        <Modal.Content className="modal-content-custom">
          <Form>
            <Form.Field>
              <label>Email</label>
              <input
                type="email"
                value={UserEmail}
                onChange={(e) => setUserEmail(e.target.value)}
                placeholder="Enter user's email"
                required />
            </Form.Field>
            <Form.Field>
              <label>Role</label>
              <select
                value={newUserRole}
                onChange={(e) => setNewUserRole(e.target.value)}
                required
              >
                <option value="">Select a role</option>
                <option value="Admin">Admin</option>
                <option value="volunteer">Volunteer</option>
              </select>
            </Form.Field>
            <Button type="button" primary className="add-user-button" onClick={handlePreAddUser} style={{ backgroundColor: 'rgb(143, 185, 143)', color: 'white' }}>Add User</Button>
          </Form>
        </Modal.Content>
      </Modal>

      <Modal open={showErrorModal} onClose={() => setShowErrorModal(false)} size='small'>
        <Modal.Header>Error</Modal.Header>
        <Modal.Content>
          <p>{errorMessage}</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => setShowErrorModal(false)}>Close</Button>
        </Modal.Actions>
      </Modal>



      {/* Confirmation Modal */}
      <Modal open={showConfirmModal} onClose={() => setShowConfirmModal(false)} size='small'>
        <Modal.Header>Confirm New User</Modal.Header>
        <Modal.Content className='modal-content-new-user'>
          <p style={{ marginTop: '20px' }}>Are you sure you want to create a new user? This will send the user a password reset email to set their password.</p>
        </Modal.Content>
        <Modal.Actions>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Button onClick={() => setShowConfirmModal(false)}>Cancel</Button>
            <Button onClick={createUser} style={{ backgroundColor: 'rgb(143, 185, 143)', color: 'white' }}>Yes, Add User</Button>
          </div>
        </Modal.Actions>

      </Modal>

      {/* Success Modal */}
      <Modal open={showSuccessModal} onClose={() => setShowSuccessModal(false)} size='small'>
        <Modal.Header>User Added Successfully</Modal.Header>
        <Modal.Content>
          <p>The user has been successfully added and a password reset email has been sent.</p>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => {
            setShowSuccessModal(false);
            resetFormAndCloseModal();
          }}>OK</Button>
        </Modal.Actions>
      </Modal>
    </div>


  );
};

export default Settings;