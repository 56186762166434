// VolSettings.js 

import React from 'react';
import { useNavigate } from 'react-router-dom';
import { signOut as firebaseSignOut } from 'firebase/auth';
import { auth } from '../../firebaseConfig';
import { useAuth } from '../../AuthContext';
import { Button } from 'semantic-ui-react';
import './VolSettings.css';

const VolSettings = () => {
  const navigate = useNavigate();
  const { signOut } = useAuth();

  const handleSignOut = async () => {
    try {
        await firebaseSignOut(auth);
        signOut();
        console.log("Signed out successfully");
        navigate('/login');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  return (
    <div className="vol-settings-container">
      <img src="/cashmere.png" alt="Cashmere" className="vol-settings-image" />
      <div className="sign-out-button-container">
        <Button className="sign-out-button" onClick={handleSignOut}>Sign Out</Button>
      </div>
    </div>
  );
};

export default VolSettings;
