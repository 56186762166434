// src/firebaseConfig.js
import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyB_kE3X_NVMiAr64mV901KR-19l6RT-sbg",
  authDomain: "cashmerefoodbank-d92b3.firebaseapp.com",
  databaseURL: "https://cashmerefoodbank-d92b3-default-rtdb.firebaseio.com",
  projectId: "cashmerefoodbank-d92b3",
  storageBucket: "cashmerefoodbank-d92b3.appspot.com",
  messagingSenderId: "220956092345",
  appId: "1:220956092345:web:6ad96acb546124a209f868",
  measurementId: "G-V5TFZMEHYQ"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);

// Initialize Firebase Auth and export it for use in your components
export const auth = getAuth(app);

